import { ThemeProvider, createTheme } from "@mui/material";
import { brown, deepPurple, purple, teal } from "@mui/material/colors";
import { React, createContext, useEffect, useState } from "react";

const EnvironmentContext = createContext(undefined);

const developmentTheme = createTheme({
    palette: {
        primary: teal,
        secondary: purple
    }
});

const stagingTheme = createTheme({
    palette: {
        primary: brown,
        secondary: purple
    }
});

const productionTheme = createTheme({
    palette: {
        primary: deepPurple,
        secondary: purple
    }
});

const EnvironmentProvider = ({children}) => {

    useEffect(() => {
        const savedEnvironment = localStorage.getItem("environment");
        if (savedEnvironment) {
            setActiveEnvironment(savedEnvironment);
        }
    //es-lint-disable-next-line
    }, []);

    const environmentList = ["Development", "Staging", "Production"];
    const [activeEnvironment, setActiveEnvironment] = useState("Development");

    const setEnvironmentWithStorage = (environment) => {
        setActiveEnvironment(environment);
        localStorage.setItem("environment", environment);
    }

    const environmentContext = {
        activeEnvironment,
        setActiveEnvironment: setEnvironmentWithStorage,
        environmentList
    }

    return (
        <EnvironmentContext.Provider value={environmentContext}>
            <ThemeProvider theme={activeEnvironment === "Development" ? developmentTheme : activeEnvironment === "Staging" ? stagingTheme : productionTheme}>
                {children}
            </ThemeProvider>
        </EnvironmentContext.Provider>
    )
}

export { EnvironmentContext, EnvironmentProvider };

