import { Box, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { React, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { JSONTree } from "react-json-tree";
import SaberSupportAPI from "../../apis/SaberSupportAPI";
import useAuth from "../../hooks/useAuth";
import useEnvironment from "../../hooks/useEnvironment";
import StandardModal from "../utils/StandardModal";
import ToolCard from "../utils/ToolCard";

const PlayerManagementTool = () => {

    const {auth} = useAuth();
    const {environment} = useEnvironment();
    const [state, setState] = useState({
        inputPlayerId: "",
        loadedPlayerDetails: {
            playerId: null,
            isCheater: null
        },
        loading: false,
        modalOpen: false
    });

    const parsePlayerDetailsResponse = useCallback((response) => {
        if (!Object.keys(response).includes("isCheater")) {
            // If the player has no isCheater field, it means they have not been flagged as a cheater.
            response.isCheater = false;
        }
        return response;
    }, []);

    const loadPlayerDetails = useCallback(async (playerId) => {
        setState(prevState => ({
            ...prevState,
            loading: true
        }));
        const [success, response] = await SaberSupportAPI.getPlayerDetails(auth.user.accessToken, environment.activeEnvironment, playerId);
        if (success) {
            toast.success("Loaded player details.");
            console.log(response);
            setState(prevState => ({
                ...prevState,
                loadedPlayerDetails: parsePlayerDetailsResponse(response),
                loading: false
            }));
        } else {
            toast.error("Failed to load player details: " + response);
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
        }
    }, [auth, environment, parsePlayerDetailsResponse]);

    const sendSetPlayerIsCheaterRequest = useCallback(async (playerId, isCheater) => {
        setState(prevState => ({
            ...prevState,
            loading: true
        }));
        const [success, response] = await SaberSupportAPI.setPlayerIsCheater(auth.user.accessToken, environment.activeEnvironment, 
            {
                playerId,
                isCheater
            }
        );
        if (success) {
            toast.success(`Set isCheater to ${isCheater} for player ${playerId}.`);
            // Trigger a reload of player details, which will handle setting loading to false
            loadPlayerDetails(playerId);
        } else {
            toast.error("Failed to set isCheater for player: " + response);
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
        }
    }, [auth, environment, loadPlayerDetails]);

    return (
        <>
        <StandardModal
            modalId="cheater-toggle-modal"
            open={state.modalOpen}
            onClose={() => setState(prevState => ({
                ...prevState,
                modalOpen: false
            }))}
            title="Toggle Cheater Status"
        >
            <Box sx={{ textAlign: "center" }}>
                    <Typography mb={1}>This will set player with ID <b>{state.loadedPlayerDetails.playerId}</b><br /> as <b>{state.loadedPlayerDetails.isCheater ? "not a cheater" : "a cheater"}</b>. Are you sure?</Typography>
                    <Button variant="contained" onClick={() => {
                            setState(prevState => ({
                                ...prevState,
                                modalOpen: false
                            }));
                            sendSetPlayerIsCheaterRequest(state.loadedPlayerDetails.playerId, !state.loadedPlayerDetails.isCheater);
                        }}>Yes</Button>
                    <Button variant="outlined" color="error" sx={{ ml: 4 }} onClick={() => setState(prevState => ({
                                ...prevState,
                                modalOpen: false
                            }))}>Cancel</Button>
                </Box>
        </StandardModal>

        <ToolCard title="Player Management" description="Tool to view player information, and flag or unflag a player as a cheater." isLoading={state.loading}>

            <Grid container rowSpacing={2} columnSpacing={5} mb={2}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h6">Player Search:</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Player ID"
                        required
                        error={state.inputPlayerId === ""}
                        value={state.inputPlayerId}
                        onChange={(e) => setState({...state, inputPlayerId: e.target.value})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={3} alignContent={"center"}>
                    <Button disabled={state.inputPlayerId === ""} variant="contained" onClick={() => loadPlayerDetails(state.inputPlayerId)}>Load Player Details</Button>
                </Grid>
                <Grid item xs={12} lg={3} alignContent={"center"}>
                    <Typography variant="paragraph"><b>Environment: </b>{environment.activeEnvironment}</Typography>
                </Grid>
            </Grid>

            <Divider sx={{mb: 2}} />

            {
                state.loadedPlayerDetails.playerId !== null ? 
                <>
                    <Grid container rowSpacing={2} columnSpacing={5} mb={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4">Loaded Player Details:</Typography>
                        </Grid>
                        <Grid item xs={12}><Typography variant="paragraph"><b>Player ID: </b>{state.loadedPlayerDetails.playerId}</Typography></Grid>
                        { state.loadedPlayerDetails.isCheater !== null ? <>
                            <Grid item xs={12}>
                                <Typography variant="paragraph"><b>Player Is Cheater: </b> {state.loadedPlayerDetails.isCheater ? "True" : "False"}</Typography>
                                <Button variant="contained" color="error" onClick={() => setState({...state, modalOpen: true})} sx={{ml: 2}}>
                                    Toggle
                                </Button>
                            </Grid>
                        </> : <>
                            <Grid item xs={12}><b>Player Is Cheater: </b> N/A</Grid>
                        </> }
                        <Grid item xs={12}><b>All Player Details: </b><JSONTree data={state.loadedPlayerDetails} theme="monokai" invertTheme={false} /></Grid>
                    </Grid>
                </>
                 : 
                 <>
                    <Typography variant="paragraph">Use the player search above to load a player's details.</Typography>
                 </>
            }

    
        </ToolCard>
        </>
    );

};

export default PlayerManagementTool;