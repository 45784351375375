import { createContext, React, useEffect, useState } from "react";
import toast from "react-hot-toast";
import SaberSupportAPI from "../apis/SaberSupportAPI";
import useAuth from "../hooks/useAuth";

const CurrencyContext = createContext(undefined);

const CurrencyProvider = ({ children }) => {

    const [currencyList, setCurrencyList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {auth} = useAuth();

    const getCurrencyList = async () => {
        console.log("getCurrencyList");
        setLoading(true);
        let response = await SaberSupportAPI.getCurrencyList(auth.user.accessToken);
        if (response === null) {
            console.error("Unable to load currencies from API.");
            toast.error("Couldn't load currency list.");
        } else {
            setCurrencyList(response);
        }
        setLoading(false);
    }

    const updateCurrencyList = async (data) => {
        if (!Array.isArray(data) || data.some(x => typeof(x) !== 'string')) {
            // Invalid input
            toast.error("Couldn't save currency list - data is invalid.");
            return;
        }

        setLoading(true);
        let [success, response] = await SaberSupportAPI.updateCurrencyList(auth.user.accessToken, data);
        setLoading(false);
        if (success) {
            toast.success("Currency list saved.");
            // Refresh the currency list from the server to ensure the data is up-to-date
            getCurrencyList();
        } else {
            console.error("Unable to save currencies to API - response:", response);
            toast.error("Couldn't save currency list - " + response);
        }
    }

    useEffect(() => {
        getCurrencyList();
        // eslint-disable-next-line
    }, []);

    const currencyContext = {
        currencyList,
        loading,
        updateCurrencyList
    }

    return (
        <CurrencyContext.Provider value={currencyContext}>
            {children}
        </CurrencyContext.Provider>
    );
};

export { CurrencyContext, CurrencyProvider };

