import { useContext } from "react";
import { AuthContext, AuthControllerContext } from "../contexts/AuthContext";

const useAuth = () => {
    const authContext = useContext(AuthContext);
    const authControllerContext = useContext(AuthControllerContext);

    return {auth: authContext, authController: authControllerContext};
}

export default useAuth;