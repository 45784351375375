import { Backdrop, Card, CircularProgress, Typography } from "@mui/material";
import { React } from "react";

const ToolCard = ({title, description, isLoading, children}) => {
    return (
        <Card variant="outlined" sx={{ p: 2, position: "relative" }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
                open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h4" mb={1}>{title}</Typography>
            <Typography color={"text.secondary"} mb={2}>{description}</Typography>
            {children}
        </Card>
    );
}

export default ToolCard;