import BookIcon from '@mui/icons-material/Book';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MenuIcon from '@mui/icons-material/Menu';
import MoneyIcon from '@mui/icons-material/Money';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { React, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useEnvironment from '../../hooks/useEnvironment';
import AuthSignInOutButton from '../auth/AuthSignInOutButton';
import EnvironmentSwitcher from './EnvironmentSwitcher';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const DashboardNav = ({ children }) => {
    
    const [open, setOpen] = useState(true);
    const {auth} = useAuth();
    const {environment} = useEnvironment();
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
  
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{ boxShadow: 0 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{ mr: 2 }}
            >
              {open ? <ChevronLeftIcon /> : <MenuIcon /> }
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              SABER Support Panel
            </Typography>
            <Typography variant='subtitle1' noWrap component="div" sx={{ ml: "auto"}}>
              Active Environment: {environment.activeEnvironment}
            </Typography>
            <EnvironmentSwitcher />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
            <DrawerHeader sx={{ backgroundColor: 'primary.main', justifyContent: 'flex-start', px: 3}}>
                <Typography variant='subtitle' color={'white'}>Signed in as <br />{auth.user.displayName ?? auth.user.email}</Typography>
            </DrawerHeader>
            <Divider />
          <List>
            <ListItem key={"Discrete Credit Tool"} disablePadding>
              <NavLink to="/dashboard/discreteCreditTool" 
                style={({ isActive }) => ({
                  background: isActive ? '#d1d1d1' : '#fff',
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%'
                })}>
                <ListItemButton>
                    <ListItemIcon>
                        <MoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Discrete Credit Tool"} />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem key={"Global Credit Tool"} disablePadding>
              <NavLink to="/dashboard/globalCreditTool" 
                style={({ isActive }) => ({
                  background: isActive ? '#d1d1d1' : '#fff',
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%'
                })}>
                <ListItemButton>
                    <ListItemIcon>
                        <PublicIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Global Credit Tool"} />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem key={"Currency Editor"} disablePadding>
              <NavLink to="/dashboard/currencyEditor" 
                style={({ isActive }) => ({
                  background: isActive ? '#d1d1d1' : '#fff',
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%'
                })}>
                  <ListItemButton>
                      <ListItemIcon>
                          <CurrencyExchangeIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Currency Editor"} />
                  </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem key={"Player Management"} disablePadding>
              <NavLink to="/dashboard/playerManagement" 
                style={({ isActive }) => ({
                  background: isActive ? '#d1d1d1' : '#fff',
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%'
                })}>
                  <ListItemButton>
                      <ListItemIcon>
                          <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Player Management"} />
                  </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem key={"Audit Log"} disablePadding>
              <NavLink to="/dashboard/auditLog" 
                style={({ isActive }) => ({
                  background: isActive ? '#d1d1d1' : '#fff',
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%'
                })}>
                  <ListItemButton>
                      <ListItemIcon>
                          <BookIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Audit Log"} />
                  </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem key={"Leaderboard Search"} disablePadding>
              <NavLink to="/dashboard/leaderboardSearch" 
                style={({ isActive }) => ({
                  background: isActive ? '#d1d1d1' : '#fff',
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%'
                })}>
                  <ListItemButton>
                      <ListItemIcon>
                          <LeaderboardIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Leaderboard Search"} />
                  </ListItemButton>
              </NavLink>
            </ListItem>
            <Divider />
            <ListItem key={"Sign Out"}>
              <AuthSignInOutButton />
            </ListItem>
          </List>
        </Drawer>
        <Main sx={{ pt: 10 }} open={open}>
          {children}
        </Main>
      </Box>
    );

};

export default DashboardNav;
