import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Backdrop, Box, Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { React, useState } from "react";
import useCurrency from "../../hooks/useCurrency";

const CreditRewardCreator = ({rewardValue, setRewardValue, amountIsValidFunction}) => {

    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [autocompleteValue, setAutocompleteValue] = useState("");
    const currency = useCurrency();

    const removeKeyFromRewardValue = (key) => {
        console.log("Deleting key:", key);
        let newRewardValue = {...rewardValue};
        delete newRewardValue[key];
        setRewardValue(newRewardValue);
    }

    const clearRewardValue = () => setRewardValue({});

    return (
        <Box variant="outlined" padding={4} sx={{ position: "relative", background: "#f1f1f1" }}>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
                open={currency.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant='h4' textAlign={"center"} mb={2}>Reward Content</Typography>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Currency Name</TableCell>
                            <TableCell sx={{maxWidth: 200}}>Amount</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { Object.keys(rewardValue).map((key, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>
                                        <TextField
                                            label="Amount"
                                            required
                                            error={!amountIsValidFunction(rewardValue[key])}
                                            value={rewardValue[key]}
                                            onChange={(e) => setRewardValue({...rewardValue, [key]: e.target.value})}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton 
                                            color="error" 
                                            size="large" 
                                            aria-label="delete"
                                            onClick={() => removeKeyFromRewardValue(key)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        { Object.keys(rewardValue).length === 0 && <TableRow><TableCell colSpan={3}>Add a currency from the list below.</TableCell></TableRow> }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
                <Autocomplete 
                        id="currencySelect"
                        value={selectedCurrency}
                        onChange={(_, newValue) => setSelectedCurrency(newValue)}
                        inputValue={autocompleteValue}
                        onInputChange={(_, newInputValue) => setAutocompleteValue(newInputValue)}
                        renderInput={(params) => <TextField {...params} label="Currency" variant="outlined" />}
                        sx={{width: 300, mr: 2, background: "#fff"}}
                        options={currency.currencyList.filter(x => !Object.keys(rewardValue).includes(x))}
                    />
                <Button 
                    variant="contained" 
                    color="secondary" 
                    disabled={selectedCurrency === null}
                    onClick={() => {
                        setRewardValue({...rewardValue, [selectedCurrency]: "0"});
                        setAutocompleteValue("");
                        setSelectedCurrency(null);
                    }}
                    >Add Currency</Button>
                <Button variant="contained" color="warning" disabled={Object.keys(rewardValue).length === 0} onClick={clearRewardValue} sx={{ ml: "auto" }}>Clear</Button>
            </Box>
        </Box>
    )

}

export default CreditRewardCreator;