import { Button } from "@mui/material";
import { React } from "react";
import useAuth from "../../hooks/useAuth";

const AuthSignInOutButton = (props) => {

    const {auth, authController} = useAuth();
    
    if (auth.authenticated) {
        return (
            <Button
              onClick={() => authController.signOutAccount()}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Out
            </Button>
        );
    } else {
        return (
            <Button
              onClick={() => authController.signInWithMK()}
              fullWidth
              color="secondary"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In With MK
            </Button>
        );
    }

}

export default AuthSignInOutButton;