import { React } from "react";
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import AuditLogViewer from "../components/auditLog/AuditLogViewer";
import CurrencyEditor from "../components/creditTool/CurrencyEditor";
import DiscreteCreditTool from "../components/creditTool/DiscreteCreditTool";
import GlobalCreditTool from "../components/creditTool/GlobalCreditTool";
import LeaderboardSearch from "../components/leaderboards/LeaderboardSearch";
import PlayerManagementTool from "../components/playerManagement/PlayerManagementTool";
import ErrorPage from "./ErrorPage";
import LoginPage from "./LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import DashboardPage from "./dashboard/DashboardPage";

const AppRouter = () => createBrowserRouter(createRoutesFromElements(
    <>
    <Route path="/" element={<Navigate to={"/dashboard"} replace/>} errorElement={<ErrorPage />} />
    <Route path="login" element={<LoginPage />} />
    <Route path="dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}>
        <Route index path="discreteCreditTool" element={<DiscreteCreditTool />} />
        <Route path="globalCreditTool" element={<GlobalCreditTool />} />
        <Route path="auditLog" element={<AuditLogViewer />} />
        <Route path="currencyEditor" element={<CurrencyEditor />} />
        <Route path="leaderboardSearch" element={<LeaderboardSearch />} />
        <Route path="playerManagement" element={<PlayerManagementTool />} />
    </Route>
    </>
));

export default AppRouter;