import { Backdrop, CircularProgress } from "@mui/material";
import { OAuthProvider, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

const AuthContext = createContext(undefined);
const AuthControllerContext = createContext(undefined);

const AuthProvider = ({children}) => {

    useEffect(() => {
        const auth = getAuth();
        console.log("Adding an auth state listener");
        onAuthStateChanged(auth, (user) => {
            console.log("onAuthStateChanged", user);
            if (user) {
                // User is signed in
                setAuthContext({
                    authenticated: true,
                    user
                });
                toast.success("Successfully signed in as " + user.email);
            } else {
                // User is not signed in
                setAuthContext({
                    authenticated: false,
                    user: null
                });
                toast.error("Signed out.");
            }
        });
        // eslint-disable-next-line
    }, []);
    
    const [authContext, setAuthContext] = useState({
        authenticated: false,
        user: null
    });

    const [loading, setLoading] = useState(false);

    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
        // Target specific email with login hint.
        login_hint: 'user@mightykingdom.com',
        tenant: 'ed4abea3-d235-41c4-a17f-cb6862c76f09'
    });

    
    const signInWithMK = (onComplete = () => {}) => {
        setLoading(true);
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                // User is signed in.
                console.log("Successfully signed in.");
                onComplete(true);
            }).catch((error) => {
                console.error("Error while trying to sign in:")
                console.error(error);
                toast.error("Error while trying to sign in: " + error.message);
                onComplete(false);
            }).finally(() => {
               setLoading(false); 
            });
    };

    const signInWithPassword = (email, password, onComplete = () => {}) => {
        setLoading(true);
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                // User is signed in.
                console.log("Successfully signed in.");
                onComplete(true);
            }).catch((error) => {
                if (error.code === "auth/invalid-email" || error.code === "auth/invalid-credential") {
                    toast.error("Invalid email or password.");
                } else {
                    console.error("Error while trying to sign in:")
                    console.error(JSON.stringify(error));
                    toast.error("Error while trying to sign in: " + error.message);
                }
                onComplete(false);
            }).finally(() => {
                setLoading(false); 
            });
    };
    
    const signOutAccount = () => {
        setLoading(true);
        const auth = getAuth();
        signOut(auth).then(() => {
            console.log("Signed out successfully.");
        }).catch((error) => {
            console.error("Error while attempting to sign out:");
            console.error(error);
        }).finally(() => {
            setLoading(false); 
        });
    };

    const authControllerContext = {
        signInWithMK,
        signInWithPassword,
        signOutAccount
    };

    return (
        <AuthContext.Provider value={authContext}>
            <AuthControllerContext.Provider value={authControllerContext}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    id="auth-backdrop"
                    open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {children}
            </AuthControllerContext.Provider>
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthControllerContext, AuthProvider };

