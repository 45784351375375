import { Container } from "@mui/material";
import { React } from "react";
import { Outlet } from "react-router-dom";
import AuthDebugInfo from "../../components/auth/AuthDebugInfo";
import DashboardNav from "../../components/navigation/DashboardNav";
import { CurrencyProvider } from "../../contexts/CurrencyContext";
import { EnvironmentProvider } from "../../contexts/EnvironmentContext";

const DashboardPage = () => {
    return (
        <CurrencyProvider>
            <EnvironmentProvider>
                <DashboardNav>
                    <Container maxWidth="lg">
                        <AuthDebugInfo />
                        <Outlet />
                    </Container>
                </DashboardNav>
            </EnvironmentProvider>
        </CurrencyProvider>
    );
}

export default DashboardPage;