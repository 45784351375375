import { Box, Container, CssBaseline, Typography } from "@mui/material";
import { React } from "react";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {

    const error = useRouteError();
    console.error(error);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography component="h1" variant="h5">
                    Error!
                </Typography>
                <Typography variant="body1">
                    The app has encountered an error:
                </Typography>
                <Typography variant="body2">
                    {error.statusText || error.message}
                </Typography>
            </Box>
        </Container>
    )

}

export default ErrorPage;