import { React } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ProtectedRoute = ({children}) => {

    const {auth} = useAuth();
    const location = useLocation();

    if (!auth.authenticated) {
        return <Navigate to="/login" replace state={{from: location}} />;
    }

    return children;

}

export default ProtectedRoute;