import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { React, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import AuthSignInOutButton from '../components/auth/AuthSignInOutButton';
import UsernamePasswordSignIn from '../components/auth/UsernamePasswordSignIn';
import useAuth from '../hooks/useAuth';

const LoginPage = () => {

  const {auth} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.authenticated) {
      navigate(location.state?.from ?? '/');
    }
  }, [auth, location, navigate]);

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            MK Sign In
          </Typography>
          <AuthSignInOutButton />
        </Box>
        <Divider sx={{ my: 3 }}>OR</Divider>
        <UsernamePasswordSignIn />
      </Container>
  );
}

export default LoginPage;