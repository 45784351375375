import { Box, Modal } from "@mui/material";
import { React } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

const PlainModal = ({modalId, open, onClose, children}) => {
    return (
        <Modal
        id={modalId}
        open={open}
        onClose={onClose}
      >
        <Box sx={style}>
            {children}
        </Box>
      </Modal>
    );
}

export default PlainModal;