import {
    Box,
    Button,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { React, useState } from "react";
import toast from "react-hot-toast";
import SaberSupportAPI from "../../apis/SaberSupportAPI";
import useAuth from "../../hooks/useAuth";
import useEnvironment from "../../hooks/useEnvironment";
import ToolCard from "../utils/ToolCard";

const LeaderboardSearch = () => {

    const {auth} = useAuth();
    const {environment} = useEnvironment();
    const [state, setState] = useState({
        loading: false,
        search: {
            playerId: "",
            leaderboardId: ""
        },
        results: {
            bucketId: "",
            entries: [],
            searchedPlayerIndex: null
        }
    });

    const performSearch = async () => {
        setState({...state, loading: true});
        const [success, response] = await SaberSupportAPI.searchLeaderboard(auth.user.accessToken, environment.activeEnvironment, state.search);
        if (success) {
            toast.success("Loaded Leaderboard results.");
            setState({...state, 
                results: {
                    bucketId: response.output.bucketId, 
                    entries: response.output.results, 
                    searchedPlayerIndex: response.output.results.findIndex(x => x.playerId === state.search.playerId)
                }, 
                loading: false});
        } else {
            toast.error("Search failed: " + response);
            setState({...state, results: {bucketId: "", entries: [], searchedPlayerIndex: null}, loading: false});
        }
    };

    return (
        <ToolCard title="Leaderboard Search" description="Find the bucket ID and leaderboard entries for the specified player and leaderboard." isLoading={state.loading}>
            <Grid container rowSpacing={2} columnSpacing={5} mb={4}>
                <Grid item xs={12} lg={4}>
                    <TextField
                        label="Player ID"
                        required
                        error={state.search.playerId === ""}
                        value={state.search.playerId}
                        onChange={(e) => setState({...state, search: {...state.search, playerId: e.target.value}})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField
                        label="Leaderboard ID"
                        required
                        error={state.search.leaderboardId === ""}
                        value={state.search.leaderboardId}
                        onChange={(e) => setState({...state, search: {...state.search, leaderboardId: e.target.value}})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Button variant="contained" color="secondary" onClick={performSearch} disabled={state.search.playerId === "" || state.search.leaderboardId === ""}>SEARCH</Button>
                </Grid>
            </Grid>

            <Typography variant="h5" mb={2} fontWeight={"bold"}>Results</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography mb={2}><strong>Bucket ID</strong>: {state.results.bucketId}</Typography>
                <Typography mb={2}><strong>Players in Bucket</strong>: {state.results.entries.length}</Typography>    
            </Box>
            
            <TableContainer component={Paper} mb={2}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell sx={{ minWidth: 150}}>Player ID</TableCell>
                            <TableCell>Player Name</TableCell>
                            <TableCell sx={{ minWidth: 200}}>Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { state.results.entries.length === 0 && <TableRow><TableCell colSpan={4}>No results found</TableCell></TableRow> }
                        { state.results.searchedPlayerIndex !== null && 
                            <TableRow sx={{ backgroundColor: "grey.200", fontWeight: "bold", borderBottom: "3px solid grey"}}>
                                <TableCell sx={{fontWeight: 'inherit'}}>{parseInt(state.results.entries[state.results.searchedPlayerIndex].rank) + 1}</TableCell>
                                <TableCell sx={{fontWeight: 'inherit'}}>{state.results.entries[state.results.searchedPlayerIndex].playerId}</TableCell>
                                <TableCell sx={{fontWeight: 'inherit'}}>{state.results.entries[state.results.searchedPlayerIndex].playerName}</TableCell>
                                <TableCell sx={{fontWeight: 'inherit'}}>{state.results.entries[state.results.searchedPlayerIndex].score}</TableCell>
                            </TableRow>
                        }
                        { state.results.entries.map(x => (
                            <TableRow key={x.rank} sx={{ backgroundColor: x.rank === state.results.searchedPlayerIndex ? "grey.200" : "", fontWeight: x.rank === state.results.searchedPlayerIndex ? "bold" : ""}}>
                                <TableCell sx={{fontWeight: 'inherit'}}>{parseInt(x.rank) + 1}</TableCell>
                                <TableCell sx={{fontWeight: 'inherit'}}>{x.playerId}</TableCell>
                                <TableCell sx={{fontWeight: 'inherit'}}>{x.playerName}</TableCell>
                                <TableCell sx={{fontWeight: 'inherit'}}>{x.score}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </ToolCard>
    );
}

export default LeaderboardSearch;