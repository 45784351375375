import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { React, useState } from 'react';
import useAuth from '../../hooks/useAuth';

const UsernamePasswordSignIn = () => {

    const [validationMessage, setValidationMessage] = useState(null);
    const {authController} = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get('email') === '' || data.get('password') === '') {
            setValidationMessage('Please enter an email and password');
            return;
        }

        setValidationMessage(null);
        authController.signInWithPassword(data.get('email'), data.get('password'), onRequestComplete);
    };

    const onRequestComplete = (success) => {
        if (success) {
            return;
        } 
        setValidationMessage('Sign in failed.');
    };

    return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Typography component="h1" variant="h5">
                External Sign In
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"/>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    sx={{mb: 3}}/>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mb: 2 }}>
                    Sign In
                </Button>
                {validationMessage && <Typography textAlign={'center'} sx={{color: 'red', mb: 3}} variant='body1'>{validationMessage}</Typography>}
            </Box>
        </Box>
    );

};

export default UsernamePasswordSignIn;