import { ChevronRight } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from "@mui/material";
import { React } from "react";
import useAuth from "../../hooks/useAuth";

const AuthDebugInfo = () => {
    
    const { auth } = useAuth();

    return (
        <Accordion sx={{mb: 4, backgroundColor: 'grey.200'}}>
            <AccordionSummary>
                <Typography fontWeight={"bold"}>Auth Debug Info</Typography>
                <ChevronRight />
            </AccordionSummary>
                <AccordionDetails>
                    <TextField multiline maxRows={4} fullWidth label="Auth Token" value={auth.authenticated ? auth.user.accessToken : "N/A"} />
                    <p>User ID: {auth.authenticated ? auth.user.uid : "N/A"}</p>
                    <p>User Email: {auth.authenticated ? auth.user.email : "N/A"}</p>
                </AccordionDetails>
        </Accordion>
    )

};

export default AuthDebugInfo;