import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import AppRouter from './routes/AppRouter';

const firebaseConfig = {
  apiKey: "AIzaSyCfJ9p2f9NGk7-A_gjlNYuM_fHTVdH0ERM",
  authDomain: "saber-support.firebaseapp.com",
  projectId: "saber-support",
  storageBucket: "saber-support.appspot.com",
  messagingSenderId: "617046992218",
  appId: "1:617046992218:web:b6518632d342cea6803f63"
};

const firebaseApp = initializeApp(firebaseConfig);
console.log(firebaseApp);

const defaultTheme = createTheme();
const router = AppRouter();

const App = () => {
  return (
    <>
    <Toaster
      position='top-center'
      gutter={8}
    />
    <ThemeProvider theme={defaultTheme}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
    </>
  );
}

export default App;
