import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { JSONTree } from 'react-json-tree';
import SaberSupportAPI from "../../apis/SaberSupportAPI";
import useAuth from "../../hooks/useAuth";
import PlainModal from '../utils/PlainModal';
import ToolCard from '../utils/ToolCard';

const AuditLogViewer = () => {

    const [loading, setLoading] = useState(false);
    const [auditLogRows, setAuditLogRows] = useState([]);
    const [modalState, setModalState] = useState({open: false, data: null});
    const {auth} = useAuth();
    
    const loadAuditLog = async () => {
        setLoading(true);
        const result = await SaberSupportAPI.getAuditLog(auth.user.accessToken);
        if (result === null) {
            console.error("Unable to load audit log from API.");
            toast.error("Unable to load audit log from API.");
        } else {
            setAuditLogRows(result);
        }
        setLoading(false);
    };

    const showDataModal = (data) => {
        setModalState({
            open: true,
            data: JSON.parse(data)
        });
    }

    useEffect(() => {
        loadAuditLog();
        // eslint-disable-next-line
    }, []);

    const auditLogColumns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'user_id', headerName: 'User', width: 200 },
        { field: 'timestamp', headerName: 'Timestamp', width: 150 },
        { field: 'action', headerName: 'Action', width: 300 },
        { field: 'data', headerName: 'Data', width: 300, 
            renderCell: (params) => (
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.value}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton variant="contained" size="small" onClick={() => showDataModal(params.value)}>
                            <ZoomInIcon />
                        </IconButton>
                    </Box>
                </Box>
            )
         },
        { field: 'response_code', headerName: 'Response', width: 80 },
    ];

    return (
        <ToolCard title="Audit Log" description="View all audit logs for the Saber Support portal." isLoading={loading}>
            <PlainModal modalId="auditLogDataModal" open={modalState.open} onClose={() => setModalState({open: false, data: null})}>
                <JSONTree data={modalState.data} theme="monokai" invertTheme={true} />
            </PlainModal>

            <DataGrid 
                columns={auditLogColumns}
                rows={auditLogRows}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 50 },
                    }
                }}
                pageSizeOptions={[50, 100]}
            />
        </ToolCard>
    );

};

export default AuditLogViewer;