import { ChevronRight } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import { React, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { JSONTree } from "react-json-tree";
import ToolCard from "../utils/ToolCard";
import CreditRewardCreator from "./CreditRewardCreator";

const GlobalCreditTool = () => {
    const [state, setState] = useState({
        id: "",
        panelText: "",
        panelTitle: "",
        rewardValue: {},
        useI2LocForPanelText: false,
        useI2LocForPanelTitle: false
    });
    
    const currencyAmountIsValid = useCallback((amount) => amount !== "" && !isNaN(Number(amount)) && Math.abs(Number(amount)) !== Infinity, []);
    const requestInputIsValid = useCallback((creditToolData) => {
        return (
            creditToolData.id !== "" &&
            creditToolData.panelText !== "" &&
            creditToolData.panelTitle !== "" &&
            Object.keys(creditToolData.rewardValue).length > 0 &&
            Object.values(creditToolData.rewardValue).every(v => currencyAmountIsValid(v))
        );
    }, [currencyAmountIsValid]);

    const creditToolOutput = useMemo(() => {
        if (!requestInputIsValid(state)) {
            return null;
        }

        let creditToolData = {
            Id: state.id,
            PanelText: state.panelText,
            PanelTitle: state.panelTitle,
            RewardValue: state.rewardValue,
            UseI2LocForPanelText: state.useI2LocForPanelText,
            UseI2LocForPanelTitle: state.useI2LocForPanelTitle
        }
        Object.keys(creditToolData.RewardValue).map(key => creditToolData.RewardValue[key] = Number(creditToolData.RewardValue[key]));
        var today = new Date().toJSON().slice(0, 10);
        creditToolData.Id = today + "-" + creditToolData.Id;
        return creditToolData;
    }, [state, requestInputIsValid]);

    const copyCreditToolValue = () => {
        if (creditToolOutput == null) {
            toast.error("No data to copy");
            return;
        }

        navigator.clipboard.writeText(JSON.stringify(creditToolOutput));
        toast.success("Data copied to clipboard!");
    };

    return (
        <>
        <ToolCard title="Global Credit Tool Builder" description="Creates the JSON for use in a game override to award all players the specified currencies.">
            <Grid container rowSpacing={2} columnSpacing={5} mb={2}>
                <Grid item xs={12}>
                    <Typography variant="body1">Give this reward an ID in the field below. This will have today's date automatically added, and will be used to track which players have redeemed the reward. Keep it short and descriptive, like 'EventMissing'. Check the documentation in Confluence for more info on setting up a Game Override!</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Reward ID"
                        required
                        error={state.id === ""}
                        value={state.id}
                        onChange={(e) => setState({...state, id: e.target.value})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Panel Title"
                        required
                        error={state.panelTitle === ""}
                        value={state.panelTitle}
                        onChange={(e) => setState({...state, panelTitle: e.target.value})}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Panel Text"
                        required
                        error={state.panelText === ""}
                        value={state.panelText}
                        onChange={(e) => setState({...state, panelText: e.target.value})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={6} textAlign={"center"}>
                    <FormControlLabel control={<Checkbox checked={state.useI2LocForPanelTitle} onChange={(e) => setState({...state, useI2LocForPanelTitle: e.target.checked})} inputProps={{ 'aria-label': 'controlled' }} />} label="Use i2Loc For Panel Title" />
                </Grid>
                <Grid item xs={12} lg={6} textAlign={"center"}>
                    <FormControlLabel control={<Checkbox checked={state.useI2LocForPanelText} onChange={(e) => setState({...state, useI2LocForPanelText: e.target.checked})} inputProps={{ 'aria-label': 'controlled' }} />} label="Use i2Loc For Panel Text" />
                </Grid>
                <Grid item xs={12}>
                    <CreditRewardCreator rewardValue={state.rewardValue} setRewardValue={(value) => setState({...state, rewardValue: value})} amountIsValidFunction={currencyAmountIsValid} />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!requestInputIsValid(state)} variant="contained" onClick={copyCreditToolValue} sx={{mb: 2}}>Copy JSON</Button>
                    <Accordion sx={{backgroundColor: 'grey.200'}}>
                        <AccordionSummary>
                            <Typography>JSON Values</Typography>
                            <ChevronRight />
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                creditToolOutput == null ? <p>Form data is invalid.</p> : <JSONTree data={creditToolOutput} theme="monokai" invertTheme={false} hideRoot={true} />
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

        </ToolCard>

        </>
    );
};

export default GlobalCreditTool;