import { Box, Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import { React, useState } from "react";
import toast from "react-hot-toast";
import SaberSupportAPI from "../../apis/SaberSupportAPI";
import useAuth from "../../hooks/useAuth";
import useEnvironment from "../../hooks/useEnvironment";
import SimpleListEditor from "../utils/SimpleListEditor";
import StandardModal from "../utils/StandardModal";
import ToolCard from "../utils/ToolCard";
import CreditRewardCreator from "./CreditRewardCreator";

const DiscreteCreditTool = () => {

    const {auth} = useAuth();
    const {environment} = useEnvironment();
    const [state, setState] = useState({
        playerId: "",
        panelText: "",
        panelTitle: "",
        rewardValue: {},
        useI2LocForPanelText: false,
        useI2LocForPanelTitle: false
    });
    const [bulkMode, setBulkMode] = useState(false);
    const [bulkPlayerIds, setBulkPlayerIds] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const sendCreditToolRequest = async (creditToolData) => {
        setIsLoading(true);
        // Convert string values of rewardValue to numbers
        Object.keys(creditToolData.rewardValue).map(key => creditToolData.rewardValue[key] = Number(creditToolData.rewardValue[key]));

        if (bulkMode) {

            let bulkCreditToolData = {...state, playerIdList: bulkPlayerIds, playerId: null};
            const [success, output] = await SaberSupportAPI.sendBulkDiscreteCreditToolRequest(auth.user.accessToken, environment.activeEnvironment, bulkCreditToolData);
            if (!success) {
                toast.error("Credit Tool Request Failed: " + output);
            } else {
                let outputMsg = "";

                if (output.failureIds.length > 0) {
                    let failureMsg = "Failed to apply credits to players: " + output.failureIds.join(", ");
                    toast.error(failureMsg);
                    outputMsg += failureMsg;
                }

                if (output.successIds.length > 0) {
                    let successMsg = "Successfully applied credits to players: " + output.successIds.join(", ");
                    toast.success(successMsg);
                }

                setErrorMessage(outputMsg);
            }

        } else {
            const [success, output] = await SaberSupportAPI.sendDiscreteCreditToolRequest(auth.user.accessToken, environment.activeEnvironment, creditToolData)
            
            let outputMsg = "";

            if (!success) {
                outputMsg = "Credit Tool Request Failed: " + output;
                toast.error(outputMsg);
            } else {
                toast.success("Success: Credit applied to player " + creditToolData.playerId);
            }

            setErrorMessage(outputMsg);
        }

        setIsLoading(false);
    }

    const requestInputIsValid = (creditToolData, bulkCreditToolPlayerIds, bulkModeActive) => {
        let playerIdIsValid = bulkModeActive ? bulkCreditToolPlayerIds.length > 0 : creditToolData.playerId !== "";
        return (
            playerIdIsValid &&
            creditToolData.panelText !== "" &&
            creditToolData.panelTitle !== "" &&
            Object.keys(creditToolData.rewardValue).length > 0 &&
            Object.values(creditToolData.rewardValue).every(v => currencyAmountIsValid(v))
        );
    }
    const currencyAmountIsValid = (amount) => amount !== "" && !isNaN(Number(amount)) && Math.abs(Number(amount)) !== Infinity;

    return (
        <>
        <StandardModal 
            modalId="discrete-credit-tool-modal" 
            open={modalOpen}
            onClose={() => setModalOpen(false)} 
            title="Confirm Credit Details" 
            description="Double check the details below, then hit Submit to send.">
                <TableContainer variant="outlined" component={Paper} sx={{ mb: 3 }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                {
                                    bulkMode ? 
                                        <><TableCell>Player IDs</TableCell><TableCell>{bulkPlayerIds.join(", ")}</TableCell></> 
                                    : 
                                        <><TableCell>Player ID</TableCell><TableCell>{state.playerId}</TableCell></>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>Panel Title</TableCell><TableCell>{state.panelTitle}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Panel Text</TableCell><TableCell>{state.panelText}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Use i2Loc for Panel Text</TableCell><TableCell>{state.useI2LocForPanelText.toString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Use i2Loc for Panel Title</TableCell><TableCell>{state.useI2LocForPanelTitle.toString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Reward Value</TableCell><TableCell>{JSON.stringify(state.rewardValue)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ textAlign: "center" }}>
                    <Typography mb={1}>Current Environment: <b>{environment.activeEnvironment}</b></Typography>
                    <Button variant="contained" onClick={() => {
                        setModalOpen(false);
                        sendCreditToolRequest(state);
                        }}>Submit Credit</Button>
                    <Button variant="outlined" color="error" sx={{ ml: 4 }} onClick={() => setModalOpen(false)}>Cancel</Button>
                </Box>
        </StandardModal>

        <ToolCard title="Discrete Credit Tool" description="Awards the selected currencies to a single player, displayed with the title and message provided." isLoading={isLoading}>
            <Grid container rowSpacing={2} columnSpacing={5} mb={2}>
                <Grid item xs={12}>
                    <FormControlLabel 
                        control={
                            <Checkbox checked={bulkMode} 
                                onChange={(e) => setBulkMode(e.target.checked)} 
                                inputProps={{ 'aria-label': 'controlled' }} />
                        } 
                        label="Bulk Mode (send to multiple players at once)" />
                </Grid>
                <Grid item xs={12} lg={bulkMode ? 12 : 6}>
                    {
                        bulkMode ?
                            <SimpleListEditor state={bulkPlayerIds} setState={setBulkPlayerIds} allowDuplicates={false} label={"Player IDs"} allowBulkAdd={true}/>
                        :
                            <TextField
                                label="Player ID"
                                required
                                error={state.playerId === ""}
                                value={state.playerId}
                                onChange={(e) => setState({...state, playerId: e.target.value})}
                                fullWidth
                            />
                    }
                </Grid>
                <Grid item xs={12} lg={bulkMode ? 12 : 6}>
                    <TextField
                        label="Panel Title"
                        required
                        error={state.panelTitle === ""}
                        value={state.panelTitle}
                        onChange={(e) => setState({...state, panelTitle: e.target.value})}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Panel Text"
                        required
                        error={state.panelText === ""}
                        value={state.panelText}
                        onChange={(e) => setState({...state, panelText: e.target.value})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={6} textAlign={"center"}>
                    <FormControlLabel control={<Checkbox checked={state.useI2LocForPanelTitle} onChange={(e) => setState({...state, useI2LocForPanelTitle: e.target.checked})} inputProps={{ 'aria-label': 'controlled' }} />} label="Use i2Loc For Panel Title" />
                </Grid>
                <Grid item xs={12} lg={6} textAlign={"center"}>
                    <FormControlLabel control={<Checkbox checked={state.useI2LocForPanelText} onChange={(e) => setState({...state, useI2LocForPanelText: e.target.checked})} inputProps={{ 'aria-label': 'controlled' }} />} label="Use i2Loc For Panel Text" />
                </Grid>
                <Grid item xs={12}>
                    <CreditRewardCreator rewardValue={state.rewardValue} setRewardValue={(value) => setState({...state, rewardValue: value})} amountIsValidFunction={currencyAmountIsValid} />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!requestInputIsValid(state, bulkPlayerIds, bulkMode)} variant="contained" onClick={() => setModalOpen(true)}>Send Credit</Button>
                </Grid>
                { errorMessage && 
                    <Grid item xs={12}>
                        <Typography color={"error.main"}>{errorMessage}</Typography>
                    </Grid> 
                }
            </Grid>
        </ToolCard>

        </>
    );

}

export default DiscreteCreditTool;