import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, List, ListItem, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { React, useState } from "react";

/**
 * A simple list editor component that allows the user to add and remove items
 * from a list.
 * 
 * @param {array} state - The current state of the list.
 * @param {function} setState - A function to update the state of the list.
 * @returns {ReactElement} - A React element representing the editor.
 */
const SimpleListEditor = ({state, setState, label, allowDuplicates = false, allowBulkAdd = false}) => {

    const [inputState, setInputState] = useState("");

    const inputIsValid = (input) => {
        return input !== "" && (allowDuplicates || !state.includes(input));
    };

    const addElement = (input) => {
        if (!inputIsValid(input)) {
            return;
        }
        setState([...state, input]);
        setInputState("");
    };

    const bulkAddElement = (input) => {
        if (!inputIsValid(input)) {
            return;
        }
        setState([...state, ...input.split(",").map(x => x.trim())]);
        setInputState("");
    };

    const removeElement = (index) => {
        setState(state.filter((_, i) => i !== index));
    };

    return (
        <Box sx={{ border: 1, borderRadius: 1, borderColor: 'divider', p: 2 }}>
            {label && <Typography variant="h6">{label}</Typography>}
            <List>
                {state.length === 0 && <ListItem>No items in list.</ListItem>}
                {state.map((x, i) => 
                    <ListItem key={i}>
                        {x}
                        <IconButton sx={{ ml: 2 }} variant="contained" size="small" color="error" onClick={() => removeElement(i)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                )}
            </List>
            <Typography variant="caption" sx={{ display: "block", mb: 2 }}>{state.length} items in list.</Typography>
            <TextField id="simple-list-input" label="Add Item" variant="outlined" size="small" sx={{ mb: 2, display: "block" }} fullWidth value={inputState} onChange={(e) => setInputState(e.target.value)} />
            <Button variant="contained" color="primary" sx={{ mb: 2, ml: 2 }} onClick={() => addElement(inputState)} disabled={!inputIsValid(inputState)}>ADD</Button>
            { allowBulkAdd && <>
                <Button variant="contained" color="success" sx={{ mb: 2, ml: 2 }} onClick={() => bulkAddElement(inputState)} disabled={!allowBulkAdd || !inputIsValid(inputState)}>BULK ADD</Button>
                <Typography variant="caption" sx={{ display: "block" }}>Use bulk add to add multiple items at once, separated by commas. Note that any spaces at the start or end of items will be removed.</Typography>
            </> }
        </Box>
    );
}

SimpleListEditor.propTypes = {
    state: PropTypes.array.isRequired,
    setState: PropTypes.func.isRequired,
    label: PropTypes.string,
    allowDuplicates: PropTypes.bool,
    allowBulkAdd: PropTypes.bool
};

export default SimpleListEditor;