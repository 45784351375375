import { Box, Modal, Typography } from "@mui/material";
import { React } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

const StandardModal = ({modalId, open, onClose, title, description, children}) => {
    return (
        <Modal
        id={modalId}
        open={open}
        onClose={onClose}
        aria-labelledby={modalId + "-modal-title"}
        aria-describedby={modalId + "-modal-description"}
      >
        <Box sx={style}>
            <Typography id={modalId + "-modal-title"} sx={{ mb: 2 }} variant="h6" component="h2">{title}</Typography>
            <Typography id={modalId + "-modal-description"} sx={{ mb: 2 }}>{description}</Typography>
            {children}
        </Box>
      </Modal>
    );
}

export default StandardModal;