import axios from "axios";

const baseURL = "https://sabersupport-api.mighty-kingdom.workers.dev";
const SaberSupportAPI = {

    getCurrencyList: async (accessToken) => {
        try {
            const response = await axios({
                method: 'get',
                url: baseURL + '/currencyList',
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            console.log(response);
            return response.data.body;
        } catch (e) {
            console.error("SaberSupportAPI :: getCurrencyList", e);
            return null;
        }
    },

    updateCurrencyList: async (accessToken, currencyList) => {
        try {
            const response = await axios({
                method: 'put',
                url: baseURL + `/currencyList`,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                data: {currencyList}
            });
            console.log("SaberSupportAPI :: updateCurrencyList", response);

            // This is how UGS indicates the call was successful
            if (response.data.success) {
                return [true, response.data.message];
            }
            return [false, response.data.error];
        } catch (e) {
            console.error("SaberSupportAPI :: updateCurrencyList", e);
            return [false, e.response.data.error];
        }
    },

    sendDiscreteCreditToolRequest: async (accessToken, environmentName, creditToolData) => {
        try {
            const response = await axios({
                method: 'post',
                url: baseURL + `/api/${environmentName}/creditToolDiscrete`,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                data: creditToolData
            });
            console.log("SaberSupportAPI :: sendDiscreteCreditToolRequest", response);

            // This is how UGS indicates the call was successful
            if (response.data.statusText === "OK") {
                return [true, response.data.body.output];
            }
            return [false, response.data.body.details[0].message];
        } catch (e) {
            console.error("SaberSupportAPI :: sendDiscreteCreditToolRequest", e);
            if (e.response) {
                return [false, e.response.data.error];
            }
            return [false, e.message];
        }
    },

    sendBulkDiscreteCreditToolRequest: async (accessToken, environmentName, bulkCreditToolData) => {
        try {
            const response = await axios({
                method: 'post',
                url: baseURL + `/api/${environmentName}/creditToolDiscreteBulk`,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                data: bulkCreditToolData
            });
            console.log("SaberSupportAPI :: sendBulkDiscreteCreditToolRequest", response);

            // This is how UGS indicates the call was successful
            if (response.data.statusText === "OK") {
                return [true, response.data.body.output];
            }
            return [false, response.data.body.details[0].message];
        } catch (e) {
            console.error("SaberSupportAPI :: sendBulkDiscreteCreditToolRequest", e);
            if (e.response) {
                return [false, e.response.data.error];
            }
            return [false, e.message];
        }
    },

    getAuditLog: async (accessToken) => {
        try {
            const response = await axios({
                method: 'get',
                url: baseURL + '/auditlog',
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            console.log(response);
            return response.data.body;
        } catch (e) {
            console.error("SaberSupportAPI :: getAuditLog", e);
            return null;
        }
    },

    searchLeaderboard: async (accessToken, environmentName, leaderboardSearchData) => {
        try {
            const response = await axios({
                method: 'post',
                url: baseURL + `/api/${environmentName}/leaderboardSearch`,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                data: leaderboardSearchData
            });
            console.log("SaberSupportAPI :: searchLeaderboard", response);

            // This is how UGS indicates the call was successful
            if (response.data.statusText === "OK") {
                return [true, response.data.body];
            }
            return [false, response.data.body.details[0].message];
        } catch (e) {
            console.error("SaberSupportAPI :: searchLeaderboard", e);
            if (e.response) {
                return [false, e.response.data.error];
            }
            return [false, e.message];
        }
    },

    getPlayerDetails: async (accessToken, environmentName, playerID) => {
        try {
            const response = await axios({
                method: 'get',
                url: baseURL + `/api/${environmentName}/player/${playerID}`,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            console.log(response);
            // This is how UGS indicates the call was successful
            if (response.data.statusText === "OK") {
                return [true, response.data.body.output];
            }
            return [false, response.data.body.details[0].message];
        } catch (e) {
            console.error("SaberSupportAPI :: getPlayerDetails", e);
            if (e.response) {
                return [false, e.response.data.error];
            }
            return [false, e.message];
        }
    },

    setPlayerIsCheater: async (accessToken, environmentName, setPlayerIsCheaterData) => {
        try {
            const response = await axios({
                method: 'post',
                url: baseURL + `/api/${environmentName}/setPlayerIsCheater`,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                data: setPlayerIsCheaterData
            });
            console.log(response);
            // This is how UGS indicates the call was successful
            if (response.data.statusText === "OK") {
                return [true, response.data.body.output];
            }
            return [false, response.data.body.details[0].message];
        } catch (e) {
            console.error("SaberSupportAPI :: getPlayerDetails", e);
            if (e.response) {
                return [false, e.response.data.error];
            }
            return [false, e.message];
        }
    },

};

export default SaberSupportAPI;