import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { React, useState } from "react";
import toast from "react-hot-toast";
import useEnvironment from "../../hooks/useEnvironment";
import StandardModal from "../utils/StandardModal";

const EnvironmentSwitcher = () => {

    const {environment} = useEnvironment();
    const [envModalOpen, setEnvModalOpen] = useState(false);
    const [selectedEnvironment, setSelectedEnvironment] = useState(environment.activeEnvironment);

    const onOpenModal = () => {
        setSelectedEnvironment(environment.activeEnvironment);
        setEnvModalOpen(true);
    }

    const applySelectedEnvironment = () => {
        environment.setActiveEnvironment(selectedEnvironment);
        toast.success("Environment set to " + selectedEnvironment);
    }

    return (
        <>
            <Button variant='contained' color='warning' sx={{ ml: 2 }} onClick={onOpenModal}>CHANGE</Button>
            <StandardModal modalId={'env-modal'} open={envModalOpen} onClose={() => setEnvModalOpen(false)} title={'Change Active Environment'} description={'Select your active environment'}>
              <Box p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FormControl fullWidth>
                  <InputLabel id="ev-change-label">Active Environment</InputLabel>
                  <Select
                    labelId="ev-change-label"
                    label="Active Environment"
                    value={selectedEnvironment}
                    onChange={e => setSelectedEnvironment(e.target.value)}>
                      {environment.environmentList.map(env => <MenuItem key={env} value={env}>{env}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <Button variant="contained" color='secondary' sx={{ ml: 2 }} onClick={applySelectedEnvironment}>APPLY</Button>
              </Box>
            </StandardModal>
        </>
    )

};

export default EnvironmentSwitcher;